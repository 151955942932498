import { render, staticRenderFns } from "./Add.vue?vue&type=template&id=0b7fc1a3&scoped=true&"
import script from "./Add.vue?vue&type=script&lang=js&"
export * from "./Add.vue?vue&type=script&lang=js&"
import style0 from "./Add.vue?vue&type=style&index=0&id=0b7fc1a3&prod&scoped=true&lang=css&"
import style1 from "./Add.vue?vue&type=style&index=1&id=0b7fc1a3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b7fc1a3",
  null
  
)

export default component.exports